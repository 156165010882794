import { KeyValuePair, IKeyValuePair } from './KeyValuePair';
import { ImagePhoto, IImagePhoto } from './ImagePhoto';
import { Structure, IStructure } from './Structure';
import { Contacts, IContacts } from './Contacts';

export interface IUser {
	Id?: number,
	Militant?: number,
	IdMilitantSituation?: number,
	MilitantSituation?: string,
	Name?: string,
	Username?: string,
	Password?: string,
	BirthDate?: string,
	Email?: string,
	DateCreate?: string,
	Image?: IImagePhoto,
	Structure?: Array<IStructure>,
	Contacts?: IContacts,
	TypeUser?: IKeyValuePair
	IsSelected?: boolean;
	UserNameChange?: string;
	DateChange?: string;
	IdState?: number;
	IdUser?: number;
	State?: string;
	Quotas?: Array<any>;
	Payments?: Array<any>;
	Cards?: Array<any>;
	Congress?: IStructure;
	IdType?: number;
	Section?: string;
	IdProcess?: number;
	IdProcessState?: number;
	ProcessState?: string;
	QuotaValidUntil?: string;
	ImagePath?: string;
}

export class User implements IUser {
	/**
	 * Identificador Unico do utilizador.
	 */
	public Id: number;
	public Militant?: number;
	public IdMilitantSituation?: number;
	public MilitantSituation?: string;

	/**
	 * Nome do utilizador.
	 */
	public Name: string;

	public BirthDate: string;

	public DateCreate: string;

	/**
	 * Código externo/interno atribuido ao utilizador pelo criado do user.
	 */
	public Code: string;

	/**
	 * Campo usado apenas no momento de criação do utilizador.
	 */
	public Username: string;

	/**
	 * Campo usado apenas no momento de criação de utilizadores.
	 */
	public Password: string;

	/**
	 * Indica se o utilizador possui carta de condução.
	 */
	public DrivingLicense: boolean;

	/**
	 * Cargo/Função do utilizador.
	 */
	public Job: string;

	/**
	 * Objecto Image com a imagem do utilizador.
	 */
	public ImagePhoto: ImagePhoto | IImagePhoto;

	/**
	 * Array de objectos Entity.
	 * Representa as relações do utilizador com entidades.
	 */
	public Structure: Array<Structure | IStructure>;

	/**
	 * Objecto de contactos do utilizador.
	 */
	public Contacts: Contacts | IContacts;

	/**
	 * Objecto de tipo de utilizador.
	 * Representa o perfil do utilizador associado.
	 */
	public TypeUser: KeyValuePair | IKeyValuePair;
	public IsSelected: boolean;

	public UserNameChange?: string;

	public DateChange?: string;

	public IdState?: number;

	public IdUser?: number;

	public State?: string;

	public Quotas?: Array<any>;

	public Payments?: Array<any>;

	public Cards?: Array<any>;

	public Congress?: IStructure;

	public IdType?: number;

	public Section?: string;

	public IdProcess?: number;
	public IdProcessState?: number;
	public ProcessState?: string;
	public QuotaValidUntil?: string;
	public ImagePath?: string;
	/**
	 * 
	 * @param data Objecto User
	 */
	constructor(data: Partial<User> = {}) {
		data = data || {};

		this.Id = data.Id || 0;
		this.Name = data.Name || "";
		this.Username = data.Username || "";
		this.DrivingLicense = data.DrivingLicense || false;
		this.Job = data.Job || "";
		this.Structure = data.Structure || [];
		this.BirthDate = data.BirthDate || "";
		this.DateCreate = data.DateCreate || "";
		this.ImagePhoto = new ImagePhoto(data.ImagePhoto);
		this.Contacts = new Contacts(data.Contacts);
		this.TypeUser = new KeyValuePair(data.TypeUser);
		this.Militant = data.Militant || 0;
		this.IdMilitantSituation = data.IdMilitantSituation
		this.MilitantSituation = data.MilitantSituation
		this.IsSelected = data.IsSelected || false;
		this.UserNameChange = data.UserNameChange || null;
		this.DateChange = data.DateChange || null;
		this.IdState = data.IdState || null;
		this.IdUser = data.IdUser || null;
		this.State = data.State || null;
		this.Quotas = data.Quotas || [];
		this.Payments = data.Payments || [];
		this.Cards = data.Cards || [];
		this.Congress = data.Congress || null;
		this.IdType = data.IdType || null;
		this.Section = data.Section || null;
		this.IdProcess = data.IdProcess || null;
		this.IdProcessState = data.IdProcessState || null;
		this.ProcessState = data.ProcessState || null;
		this.QuotaValidUntil = data.QuotaValidUntil || null;
		this.ImagePath = data.ImagePath || null;

		if (this.Structure) {
			this.Structure.forEach(element => {
				this.Structure.push(new Structure(element));
			});
		}
	}
}

export interface IUserInfoAccess { IdLevel?: number, IdUserType?: number, Sections?: Array<any>, Locked?: boolean, Structures?: Array<any> }