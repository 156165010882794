import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[lowercaseInput]'
})
export class LowercaseInputDirective {

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event) {
        this.el.nativeElement.value = event.target.value.toLowerCase();
    }
}