import { DirectivesModule } from './directives/directives.module';

//ANGULAR MODULES
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { LOCALE_ID, NgModule } from '@angular/core';

//ENVIROMENT CONFIG
import { environment } from '../environments/environment';

//IONIC LIBS
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

//EXTERNAL LIBS
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ChartjsModule } from '@ctrl/ngx-chartjs';

//APP COMPONENTS AND MODULES
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SidemenuComponentModule } from './sidemenu/sidemenu.module';

//APP PAGES MODULES
import { AppUpdaterPageModule } from './pages/app-updater/app-updater.module';

import { IonicSelectableModule } from 'ionic-selectable';


import { DragulaModule } from 'ng2-dragula';


import localePT from '@angular/common/locales/pt-PT';

import { registerLocaleData } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

//AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class DynamicLocaleId extends String {
    constructor(protected service: TranslateService) {
        super();
    }

    toString() {
        return this.service.currentLang;
    }
}

registerLocaleData(localePT, 'pt');
@NgModule(
    {
        declarations: [
            AppComponent,
        ],
        imports: [
            HttpClientModule,
            AppRoutingModule,
            AppUpdaterPageModule,
            BrowserModule,
            SidemenuComponentModule,
            ChartjsModule,
            DirectivesModule,
            NgSelectModule,
            IonicModule.forRoot({
                mode: 'md'
            }),
            TranslateModule.forRoot(
                {
                    loader:
                    {
                        provide: TranslateLoader,
                        useFactory: (createTranslateLoader),
                        deps: [HttpClient]
                    }
                }),
            IonicStorageModule.forRoot({
                name: "pspoliticmanagement",
                storeName: "pspoliticmanagementdb",
                // driverOrder: ['indexeddb', 'websql', 'sqlite']
            }),
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
            // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
            DragulaModule.forRoot()
        ],
        providers: [
            {
                provide: RouteReuseStrategy,
                useClass: IonicRouteStrategy
            },
            {
                provide: LOCALE_ID,
                useClass: DynamicLocaleId,
                deps: [TranslateService]
            },
            IonicSelectableModule,
        ],
        bootstrap: [AppComponent]
    })
export class AppModule { }
