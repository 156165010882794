import { ICandidatureHistoricItem, ICandidatureIntervention, ICandidature } from './../models/Candidature';
import { SessionService } from './session.service';
import { KeyValuePair, IKeyValuePair } from './../models/KeyValuePair';
import { PAGES } from './../pages/Index';
import { Item } from './../models/Item.Model';
import { environment } from './../../environments/environment';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultObjectModel } from '../models/ResultObject';
import { NavController } from '@ionic/angular';
import { QuickAddress } from '../models/QuickAddress';
import { Filter } from '../models/Filter';
import { BackOfficeUser } from '../models/BackOfficeUser';

export interface ResultOperation {
	SUCCESS: number,
	NO_DATA: number,
	NO_NEW_DATA: number,
	DUPLICATED_DATA: number,
	INVALID_DATA: number,
	INVALID_REQUEST: number,
	INVALID_TOKEN: number,
	BAD_REQUEST: number,
	UNAUTHORIZED: number,
	INTERNAL_SERVER_ERROR: number,
	NOT_FOUND: number,
	WARNING: number,
	EXPIRED_DATE: number
};

@Injectable(
	{
		providedIn: 'root'
	})
export class WebService {
	/**
	 * Objecto de operações do servidor
	 */
	public static RESULT_OPERATION: ResultOperation =
		{
			SUCCESS: 0,
			NO_DATA: 1,
			NO_NEW_DATA: 2,
			DUPLICATED_DATA: 3,
			INVALID_DATA: 4,
			INVALID_REQUEST: 5,
			INVALID_TOKEN: 6,
			BAD_REQUEST: 7,
			UNAUTHORIZED: 8,
			INTERNAL_SERVER_ERROR: 9,
			NOT_FOUND: 10,
			WARNING: 11,
			EXPIRED_DATE: 12
		};

	private endPointWebservice: string;

	//private endPointUploadFile: string;

	private Options: HttpHeaders;

	private AuthorizationCode: string;

	private AccessToken: string;

	private ClientId: string;

	private ClientSecret: string;

	private IdProject: number;

	private timeToRefresh: number = 45;

	constructor(protected http: HttpClient,
		private NavController: NavController,
		private sessionService: SessionService
	) {

	}

	/**
	 * Atribui variaveis de projecto, endpoints e clients ids.
	 */
	public Init(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.Options = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + null });

			this.IdProject = environment.ProjectConfig.IdProject;

			this.endPointWebservice = environment.APIConfig.EndPoint;

			this.ClientId = environment.APIConfig.ClientId;

			this.ClientSecret = environment.APIConfig.ClientSecret;
		});
	}

	/**
	 * Gera token de acesso à API AutorizationToken.
	 * O token é usada para o metodo de login.
	 */
	public GetAuthorizationCode(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "auth/authorizationcode";

		const params: string = JSON.stringify(
			{
				"clientId": this.ClientId,
				"clientSecret": this.ClientSecret
			});

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			this.AuthorizationCode = response.Status === WebService.RESULT_OPERATION.SUCCESS ? response.Object.Code : null;

			return response;
		});
	}

	/**
	 * Metodo de autenticação de um utilizador.
	 * Para além das credenciais do utilizador necessita de um autorizationToken que deve ser pedido anteriormente.
	 * Um login com success devolve um AccessToken.
	 * @param username
	 * @param password
	 * @param authotizationToken
	 */
	public AuthUser(username: string, password: string, authorizationCode: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "auth/token";

		const params: string = JSON.stringify(
			{
				"authorizationCode": authorizationCode,
				"username": username,
				"password": password,
				"idProject": this.IdProject
			});

		return this.genericRequestPOST(path, params).then(response => {
			if (response.Status == WebService.RESULT_OPERATION.SUCCESS
				&& response.Object.AccessToken) {
				this.SetAuthorization(response.Object.AccessToken);
			}

			return response;
		});
	}

	/**
	 *  Token de validação de requests
	 * @param token
	 */
	public SetAuthorization(accessToken: string) {
		if (this.AccessToken != accessToken) this.AccessToken = accessToken;

		this.Options = this.Options.set('Authorization', 'Bearer ' + accessToken);
	}

	/**
	 *
	 * @param token
	 */
	public VerifyAuthenticationToken(token?: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "auth/token";

		token = token ? token : this.AccessToken;

		this.SetAuthorization(token);

		return this.genericRequestGET(path).then(response => {
			return response;
		});
	}



	public CreatePasswordRecoveryProcess(email: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "auth/backoffice_RecoverPassword";


		const params = { email: email };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetUserData(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users";

		return this.genericRequestGET(path).then(response => {
			return response;
		});
	}

	//#region Process
	// DEPRECATED
	public GetProcesses(pageNumber: number, itemsPerPage: number) {
		const path: string = this.endPointWebservice + "process/GetPendingProcess";
		const params = { pageNumber: pageNumber, itemsPerPage: itemsPerPage };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetProcessList(pageNumber: number, itemsPerPage: number, filter: Filter): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "process/GetProcessList";
		const params = { pageNumber: pageNumber, itemsPerPage: itemsPerPage, filter: filter };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}


	public GetProcessDetails(id: number) {
		const path: string = this.endPointWebservice + "process/GetProcessDetails";
		const params = { idProcess: id };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetProccessInterventionsTypes() {
		const path: string = this.endPointWebservice + "process/GetInterventionsTypes";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public SubmitProcessIntervention(idProcess: number, state: number, subject: string, message: string, internalMessage: string, reasons: Item[], toUpdate: boolean) {
		const path: string = this.endPointWebservice + "process/SubmitIntervention";
		const params = { idProcess: idProcess, state: state, subject: subject, message: message, internalMessage: internalMessage, reasons: reasons, toUpdate: toUpdate };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ProcessUpdateAddress(idProcess: number, address: QuickAddress) {
		const path: string = this.endPointWebservice + "process/ProcessUpdateAddress";
		const params = { idProcess: idProcess, address: address };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	//#endregion

	//#region Address
	public Address_Get_District(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "address/GetDistrict";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Address_Get_Country(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "address/GetCountry";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}


	public Address_GetCounty_By_District(IdDistrict: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "address/GetCounty";
		const params = { IdDistrict: IdDistrict };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Address_GetLocal_by_County(IdCounty: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "address/GetLocal";
		const params = { IdCounty: IdCounty };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Address_GetStreet(IdDistrict: any, IdCounty: any, IdLocal: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "address/GetStreet";
		const params = { IdDistrict: IdDistrict, IdCounty: IdCounty, IdLocal: IdLocal };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Address_FilterByPostal(pc4: any, pc3: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "address/GetAddressByPostal";
		const params = { pc4: pc4, pc3: pc3 };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public CreateNewStreet(pc4: any, pc3: any, street: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "address/CreateNewStreet";
		const params = { pc4: pc4, pc3: pc3, street: street };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetCoordinatesFromAddress(address: string): Promise<any> {
		address = address.replace(" ", "+");
		address = address.replace("++", "+");
		// const path: string = "https://api.mapbox.com/geocoding/v5/mapbox.places/"+ address +".json?access_token=pk.eyJ1IjoiZWR1Ym94IiwiYSI6ImNpcWM4bWh0ZDAwNG9odmt4cW5wcGdhbWYifQ.kNGtekFOJs7a8Gu5I0-zpQ"
		// const path: string = "https://api.mapbox.com/geocoding/v5/mapbox.places/Rua%20Dinis%20Gomes,3830-188%20ilhavo,%20portugal.json?address=Rua%20Dinis%20Gomes&country=pt&district=aveiro&access_token=pk.eyJ1IjoiZWR1Ym94IiwiYSI6ImNpcWM4bWh0ZDAwNG9odmt4cW5wcGdhbWYifQ.kNGtekFOJs7a8Gu5I0-zpQ"
		const path: string = "https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=AIzaSyBcCYMiGbjL4EKR5QVbv4UM1fGJA40VBkE";
		// const path: string = this.endPointWebservice + "address/GetLocal";
		return this.externalRequestGET(path).then((response: any) => {
			return response;
		});
	}
	//#endregion

	/**
	 * Pesquisar militantes com filtros
	 * @param expression
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 * @param structures
	 */
	public GetAllUsers(expression: string, pageNumber: number, itemsPerPage: number, filter: KeyValuePair = null, structures: Array<KeyValuePair> = null): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/GetAllUsers";
		const params = {
			expression: expression,
			structures: structures,
			filter: filter,
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public GetUserList(pageNumber: number, itemsPerPage: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/GetUserList";
		const params = { pageNumber: pageNumber, itemsPerPage: itemsPerPage, search: "", structures: [{ Id: -1, Description: "All" }], filter: [{ Id: -1, Description: "All" }] };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public Get_BackOfficeUserList(pageNumber: number, itemsPerPage: number, searchText: string, userTypes: Array<any> = []): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/Get_BackOfficeUserList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			searchText: searchText,
			userTypes: userTypes
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public Get_BackOfficeUser(idUser: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/Get_BackOfficeUser";
		const params = { idUser: idUser };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public BackOffice_ResetUserPassword(idUser: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/BackOffice_ResetUserPassword";
		const params = { idUser: idUser };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public BackOffice_EditUser(user: BackOfficeUser): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/BackOffice_EditUser";
		const params = { user: user };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public GetCharts(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "dashboard/GetCharts";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param code
	 */
	public GetAvailableFilters(code: string = null): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "common/Get_AvailableFilters";
		const params = { code: code };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Get_ProcessFilters(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "common/Get_ProcessFilters";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Get_Structure_Districts(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "common/Get_Structure_Districts";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param Id
	 * @param Value
	 */
	public GetFilter(Id: number, Value: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "common/Get_Filter";
		const params = { Id: Id, Value: Value };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetFilterUsers(search: any, structures: any, filter: any): Promise<ResultObjectModel> {
		let auxStruct: Array<any> = [];
		let auxFilter: Array<any> = [];
		if (structures instanceof Array)
			auxStruct = structures;
		else
			auxStruct.push(structures);

		if (filter instanceof Array)
			auxFilter = filter;
		else
			auxFilter.push(filter);

		const path: string = this.endPointWebservice + "users/GetAllUsers";
		const params = { search: search, structures: auxStruct, filter: auxFilter };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Get_FilterUsers(search: any, pageNumber: any, itemsPerPage: any, filters: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/GetFilterUsers";
		const params = { search: search, filters: filters, pageNumber: pageNumber, itemsPerPage: itemsPerPage };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public SearchMilitantByNumber(militantNumber: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/SearchMilitantByNumber";
		const params = { militantNumber: militantNumber };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	public GetUserForm(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/GetUserForm";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public CheckAccessToken(): Promise<any> {
		const path: string = this.endPointWebservice + "auth/token";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Get_StaticData(): Promise<any> {
		const path: string = this.endPointWebservice + "common/Get_StaticData";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetSectionForm(Id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/GetSectionForm";
		const params = { IdStructure: Id };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetUserInfo(IdUser: number): Promise<any> {
		const path: string = this.endPointWebservice + "users/GetUserInfo";
		const params = { IdUser: IdUser };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetUserDocument(guid: string): Promise<any> {
		const path: string = this.endPointWebservice + "users/GET_Document";
		const params = { guid: guid };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}



	public UserAlterState(idUser: number, idState: number, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "users/AlterState";
		const params = { idUser: idUser, state: idState, observation: observation };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Get_UserCardDetails(IdUser: number): Promise<any> {
		const path: string = this.endPointWebservice + "users/Get_UserCardDetails";
		const params = { IdUser: IdUser };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public SubmitUser(data: any, contacts: any, interests: any, structures: any): Promise<any> {
		const path: string = this.endPointWebservice + "users/SubmitUser";
		const params = { data: data, contacts: contacts, interests: interests, structures: structures };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Edit_UserStructureData(iduser: number, data: any): Promise<any> {
		const path: string = this.endPointWebservice + "users/Edit_UserStructureData";
		const params = { idUser: iduser, data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public Edit_UserBasicInfo(basicInfo: any): Promise<any> {
		const path: string = this.endPointWebservice + "users/Edit_UserBasicInfo";
		const params = { basicInfo: basicInfo };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public AddMilitantStructure(iduser: number, structure: any, admissionDate: string, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "users/Add_MilitantStructure";
		const params = { idUser: iduser, structure: structure, startDate: admissionDate, observation: observation };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public RemoveMilitantStructure(iduser: number, structure: any, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "users/Remove_MilitantStructure";
		const params = { idUser: iduser, structure: structure, observation: observation };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public EditMilitantStructureAdmissionDate(iduser: number, structure: any, admissionDate: string, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_EditStructureAdmitionDate";
		const params = { idUser: iduser, structure: structure, admitionDate: admissionDate, observation: observation };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 */
	AddBackOfficeUser(user: BackOfficeUser, access: { EndDate: string, SectionAccess: IKeyValuePair, Observation: string, Structures: Array<any>, LevelAccess: number } = null): Promise<any> {
		const path: string = this.endPointWebservice + "users/AddBackOfficeUser";
		const params = {
			user: user,
			access: access
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idUser
	 */
	LockBackOfficeUser(idUser: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/LockBackOfficeUser";
		const params = { idUser: idUser };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idUser
	 */
	GetRecoveryPasswordLink(idUser: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/GetRecoveryPasswordLink";
		const params = { idUser: idUser };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param data
	 * @param observation
	 */
	public MilitantInscription(data: any, observation: string = null, timer: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/MilitantInscription";
		const params = {
			data: data,
			observation: observation,
			timer: timer
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetMilitantInscriptionsList(pageNumber: number, itemsPerPage: number, filter: any = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/GET_MilitantInscriptions_List";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			filter: filter
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idUser
	 */
	public GetMilitantInscriptionsDetails(user: any = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/GET_MilitantInscription_Details";
		const params = {
			user: user
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idUser
	 */
	public GetMilitantInscriptionHistoricDetails(user: any = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/GET_MilitantInscription_HistoricDetails";
		const params = {
			user: user
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idUser
	 * @param data
	 */
	public EditMilitantPersonalData(idUser: number, data: any = null, timer: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/EDIT_MilitantPersonalData";
		const params = {
			idUser: idUser,
			data: data,
			timer: timer
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idUser
	 * @param structures
	 */
	public EditMilitantStructure(idUser: number, structures: any = null, timer: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/EDIT_MilitantStructure";
		const params = {
			idUser: idUser,
			structures: structures,
			timer: timer
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 *
	 * @param idUser
	 * @param data
	 */
	public EditMilitantSection(idUser: number, data: any = null, timer: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/EDIT_MilitantSection";
		const params = {
			idUser: idUser,
			data: data,
			timer: timer
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 *
	 * @param idUser
	 * @param contacts
	 */
	public EditMilitantContact(idUser: number, contacts: any = null, timer: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/EDIT_MilitantContact";
		const params = {
			idUser: idUser,
			contacts: contacts,
			timer: timer
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idUser
	 * @param interest
	 */
	public EditMilitantInterest(idUser: number, interests: any = null, timer: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/EDIT_MilitantInterest";
		const params = {
			idUser: idUser,
			interests: interests,
			timer: timer
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param iduser
	 */
	public Militant_GetSectionData(iduser: number): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_GetSectionData";
		const params = { idUser: iduser };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param iduser
	 * @param data
	 */
	public Militant_EditContact(iduser: number, data: any): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_EditContact";
		const params = { idUser: iduser, data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 *
	 * @param iduser
	 * @param data
	 */

	public Militant_EditSection(iduser: number, data: any, startDate: any, residential: boolean, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_EditSection";
		const params = { idUser: iduser, data: data, startDate: startDate, residential: residential, observation: observation };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param iduser 
	 * @param data 
	 * @param startDate 
	 * @param residential 
	 * @param observation 
	 * @returns 
	 */
	public Militant_MasterEditSection(iduser: number, data: any, startDate: any, residential: boolean, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Master_Militant_EditSection";
		const params = { idUser: iduser, data: data, startDate: startDate, residential: residential, observation: observation };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param form
	 * @param iduser
	 */
	public Militant_GetEditableForm(form: any, iduser: number = 0): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_GetEditableForm";
		const params = { form: form, idUser: iduser };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param month
	 * @param year
	 */
	public MilitantMonthSuspensionsHistoric(month: Array<number> = [], year: Array<number> = []): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_MonthSuspensionsHistoric";
		const params = { month: month, year: year };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param month
	 * @param year
	 */
	public MilitantPreviewMonthSuspensions(month: number = 0, year: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_Preview_MonthSuspensions";
		const params = { month: month, year: year };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param month
	 * @param year
	 */
	public MilitantExecuteMonthSuspensions(month: number = 0, year: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Militant_Execute_MonthSuspensions";
		const params = { month: month, year: year };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param month
	 * @param year
	 * @param idReportType
	 */
	public ReportMilitantExecuteMonthSuspensions(month: number = 0, year: number = null, idReportType: number = 0): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Report_Militant_Execute_MonthSuspensions";
		const params = { month: month, year: year, idReportType: idReportType };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param month
	 * @param year
	 * @param idReportType
	 */
	public ReportMilitantPreviewMonthSuspensions(month: number = 0, year: number = null, idReportType: number = 0): Promise<any> {
		const path: string = this.endPointWebservice + "militant/Report_Militant_Preview_MonthSuspensions";
		const params = { month: month, year: year, idReportType: idReportType };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 *
	 * @param contact
	 */
	public ValidMilitantContact(contact: any): Promise<any> {
		const path: string = this.endPointWebservice + "militant/ValidMilitantContact";
		const params = { contact: contact };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ExternalFormInitialData(): Promise<any> {
		const path = this.endPointWebservice + "externalform/ExternalForm_GET_InitialData";
		const params = {};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param iduser
	 */
	public GetSectionData(iduser: number): Promise<any> {
		const path: string = this.endPointWebservice + "common/GET_SectionsData";
		const params = { idUser: iduser };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public MilitantRegist(form: any): Promise<ResultObjectModel> {
		const path = this.endPointWebservice + "militant/MilitantRegist";

		let header = this.Options;
		header = header.set('Content-Type', 'application/octet-stream');

		return new Promise((resolve, reject) => {

			if (this.endPointWebservice === undefined) {
				resolve(new ResultObjectModel());
			}

			this.http.post<ResultObjectModel>(path, form, {
				headers: header, reportProgress: true,
				observe: 'events'
			}).toPromise().then((event: any) => {

				console.log(event);

				switch (event.type) {
					case HttpEventType.UploadProgress:
						// file.progress = Math.round(event.loaded * 100 / event.total);
						console.log("progress: ", Math.round(event.loaded * 100 / event.total));

						break;
					case HttpEventType.Response:
						resolve(new ResultObjectModel(event.body.MilitantRegistResult));
				}
			}).catch(error => {
				resolve(new ResultObjectModel());
				this.handleError(error);
			});

		});

	}

	public SaveMilitantRegist(form: any): Promise<ResultObjectModel> {
		const path = this.endPointWebservice + "militant/SaveMilitantRegist";

		let header = this.Options;
		header = header.set('Content-Type', 'application/octet-stream');

		return new Promise((resolve, reject) => {

			if (this.endPointWebservice === undefined) {
				resolve(new ResultObjectModel());
			}

			this.http.post<ResultObjectModel>(path, form, {
				headers: header, reportProgress: true,
				observe: 'events'
			}).toPromise().then((event: any) => {

				console.log(event);

				switch (event.type) {
					case HttpEventType.UploadProgress:
						// file.progress = Math.round(event.loaded * 100 / event.total);
						console.log("progress: ", Math.round(event.loaded * 100 / event.total));

						break;
					case HttpEventType.Response:
						resolve(new ResultObjectModel(event.body.SaveMilitantInscriptionResult));
				}
			}).catch(error => {
				resolve(new ResultObjectModel());
				this.handleError(error);
			});

		});

	}

	public GetMilitantForm(guid: string = ""): Promise<ResultObjectModel> {
		const path = this.endPointWebservice + "militant/GET_CandidateForm";
		const params = {
			guid: guid
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});

	}

	public VerifyMilitantExistence(identificationNumber: string = ""): Promise<ResultObjectModel> {
		const path = this.endPointWebservice + "externalform/VerifyMilitantExistence";
		const params = {
			identificationNumber: identificationNumber
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});

	}

	public GenerateReportCandidature(form: any): Promise<ResultObjectModel> {
		const path = this.endPointWebservice + "externalform/GenerateReportCandidature";
		const params = {
			data: form
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});

	}
	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetCENSavedCandidaturesList(pageNumber: number, itemsPerPage: number, filter: any = null): Promise<any> {
		const path = this.endPointWebservice + "CEN/Get_SavedCandidaturesList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			filter: filter
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});

	}
	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetCENCandidaturesList(pageNumber: number, itemsPerPage: number, filter: any = null): Promise<any> {
		const path = this.endPointWebservice + "CEN/GET_CandidaturesList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			filter: filter
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});

	}

	/**
	 *
	 * @param id
	 */
	public GetCENCandidatureDetails(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/GetCandidatureDetails";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 */
	public GetCENCandidatureFullDetails(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/GetCandidatureFullDetails";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 * @param filename
	 */
	public GetCENCandidatureDocument(id: number, filename: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/GetCandidatureDocument";
		const params = {
			id: id,
			filename: filename
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
 *
 * @param id
 * @param observation
 */
	public ApproveCENCandidature(id: number, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/AproveCandidature";
		const params = {
			id: id,
			// message: message,
			observation: observation
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 * @param observation
	 */
	public RejectCENCandidature(id: number, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/RejectCandidature";
		const params = {
			id: id,
			// message: message,
			observation: observation,
			// reasons: reasons
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 * @param id
	 */
	public CENAssociateMember(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/AssociateMember";
		const params = {
			id: id
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 */
	public ValidateCENCandidatureExistence(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/ValidateCandidatureExistence";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Pesquisar militantes com filtros
	 * @param expression
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetCENAllMembers(expression: string, pageNumber: number, itemsPerPage: number, filter: KeyValuePair = null): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "CEN/SearchMember";
		const params = {
			expression: expression,
			filter: filter,
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	/**
	 * @param id
	 */
	public GetCENMemberDetails(id: number): Promise<any> {
		const path: string = this.endPointWebservice + "CEN/GetMemberDetails";
		const params = { id: id };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * @param data
	 */
	public CENEditCandidatePersonalData(data: any): Promise<any> {
		const path: string = this.endPointWebservice + "CEN/EditCandidatePersonalData";
		const params = { data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public CENEditMemberData(member: any): Promise<any> {
		const path: string = this.endPointWebservice + "CEN/EditMemberData";
		const params = { member: member };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param iduser
	 * @param data
	 */
	public EditMemberContact(iduser: number, data: any): Promise<any> {
		const path: string = this.endPointWebservice + "CEN/EditMemberContact";
		const params = { idUser: iduser, data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 * 
	 * @param idUser 
	 * @param thematics 
	 */
	public EditMemberThematics(idUser: number, thematics: any): Promise<any> {
		const path: string = this.endPointWebservice + "CEN/EditMemberThematics";
		const params = { idUser: idUser, thematics: thematics };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param guid
	 * @param email
	 */
	public ValidateContact(guid: string, email: string): Promise<any> {
		const path: string = this.endPointWebservice + "extra/ValidateEmailToken";
		const params = { guid: guid, email: email };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	// /**
	//  *
	//  * @param number
	//  */
	// public SearchMilitantbyNumber(number: string): Promise<any> {
	// 	const path: string = this.endPointWebservice + "militant/";
	// 	const params = { number: number };
	// 	return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
	// 		return response;
	// 	});
	// }

	/**
	 *
	 * @param title
	 * @param idElectionType
	 * @param electionDate
	 * @param idStructure
	 * @param sections
	 */
	public CreateElection(title: string, idElectionType: number, electionDate: string, idStructure: number, sections: Array<any>, rolesElection: Array<any>, observation: string, dateCall: string, dateVisible: string, dateInvisible: string, haveMobilityVote: boolean, mobilityVoteDateOpen: string, mobilityVoteDateClose: string): Promise<any> {
		const path: string = this.endPointWebservice + "election/CreateElection";
		const params = {
			title: title,
			idElectionType: idElectionType,
			electionDate: electionDate,
			idStructure: idStructure,
			sections: sections,
			rolesElection: rolesElection,
			observation: observation,
			dateCall: dateCall,
			dateVisible: dateVisible,
			dateInvisible: dateInvisible,
			haveMobilityVote: haveMobilityVote,
			mobilityVoteDateOpen: mobilityVoteDateOpen,
			mobilityVoteDateClose: mobilityVoteDateClose
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Dados basicos da eleição
	 * @param idElection
	 */
	public GetElectionBasicDetails(idElection: number): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetElectionBasicDetails";
		const params = {
			idElection: idElection,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Editar eleição
	 * @param election
	 */
	public EditElection(election: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/EditElection";
		const params = {
			election: election,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Desativar eleição
	 * @param election
	 */
	public DeactivateElection(election: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/DeactivateElection";
		const params = {
			election: election,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param pageNumber 1
	 * @param itemsPerPage 50
	 * @param searchText  ""
	 */
	public GetElectionList(pageNumber: number = 1, itemsPerPage: number = 50, searchText: string = ""): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetElectionList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			searchText: searchText
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idElection
	 */
	public GetElectionDetails(idElection: number): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetElectionDetails";
		const params = {
			idElection: idElection
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Pesquisar militante, retorna dados do militante
	 * @param militantNumber
	 */
	public SearchMilitantByNumberElection(militantNumber: number, id: number = 0, type: number = 1): Promise<any> {
		const path: string = this.endPointWebservice + "election/SearchMilitantByNumber";
		const params = {
			militantNumber: militantNumber,
			id: id,
			type: type || 1
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Cria candidatura na eleição pretendida
	 * @param idElection
	 * @param name
	 * @param logins
	 */
	public CreateElectionCandidature(idElection: number, name: string, logins: Array<any>): Promise<any> {
		const path: string = this.endPointWebservice + "election/CreateElectionCandidature";
		const params = {
			idElection: idElection,
			name: name,
			logins: logins
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Bloquear/Desbloquear acesso ao responsável
	 * @param idCandidature
	 * @param user
	 */
	public LockCandidatureUser(idCandidature: number, user: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/LockCandidatureUser";
		const params = {
			idCandidature: idCandidature,
			user: user,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Adicionar delegados a uma mesa
	 * @param idElection id da mesa
	 * @param idBoard lista de delegados
	 */
	public GetTableInfo(idElection: number = 0, idBoard: number = 0): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetTableInfo";
		const params = {
			idElection: idElection,
			idBoard: idBoard
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Adicionar delegados a uma mesa
	 * @param idTable id da mesa
	 * @param delegates lista de delegados
	 * @param idCandidature candidatura
	 */
	public SetTableDelegates(idTable: number, delegates: Array<any>, idCandidature: number = 0): Promise<any> {
		const path: string = this.endPointWebservice + "election/SetTableDelegates";
		const params = {
			idTable: idTable,
			delegates: delegates,
			idCandidature: idCandidature
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Remover delegados a uma mesa
	 * @param idTable id da mesa
	 * @param delegates lista de delegados
	 * @param idCandidature candidatura
	 */
	public DeleteTableDelegates(idTable: number, delegates: Array<any>, idCandidature: number = 0): Promise<any> {
		const path: string = this.endPointWebservice + "election/DeleteTableDelegates";
		const params = {
			idTable: idTable,
			delegates: delegates,
			idCandidature: idCandidature
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param board
	 * @param idDelegate
	 * @param idDelegateType
	 * @param replicated
	 */
	public GetReportDelegate(idSection: number = -1, board: any, idCandidature: number = -1, idDelegate: number = -1, idDelegateType: number = -1, replicated: boolean = true): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetReportDelegate";
		const params = {
			idSection: idSection,
			board: board,
			idCandidature: idCandidature,
			idDelegate: idDelegate,
			idDelegateType: idDelegateType,
			replicated: replicated
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idBookType
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param searchText
	 */
	public GetPreviewElectionBook(election: any, idBookType: number, idReportType: number = 0, pageNumber: number = 0, itemsPerPage: number = 0, searchText: string = ""): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetPreviewElectionBook";
		const params = {
			election: election,
			idBookType: idBookType,
			idReportType: idReportType,
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			searchText: searchText
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Obter dados completos da candidatura
	 * @param candidature dados da candidatura
	 */
	public GetCandidatureCandidates(candidature: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetCandidatureCandidates";
		const params = {
			candidature: candidature
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Editar responsavel numa candidatura
	 * @param candidature
	 * @param userId
	 */
	public CandidatureEditUser(idCandidature: number, user: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/CandidatureEditUser";
		const params = {
			idCandidature: idCandidature,
			user: user
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * adicionar mais responsaveis a uma candidatura
	 * @param candidature
	 * @param logins
	 */
	public CandidatureCreateUser(candidature: any, logins: Array<any>): Promise<any> {
		const path: string = this.endPointWebservice + "election/CandidatureCreateUser";
		const params = {
			candidature: candidature,
			logins: logins
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 * Remover candidatura
	 * @param candidature
	 */
	public RemoveCandidature(candidature: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/RemoveCandidature";
		const params = {
			candidature: candidature
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Desativar eleição
	 * @param election
	 */
	public CalculateAddendum(election: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/CalculateAddendum";
		const params = {
			election: election
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 *
	 * @param idBookType
	 * @param reportType
	 */
	public DownloadElectionBook(election: any, idBookType: number, reportType: number, filter: { IdSection?: number, IdTable?: number } = null): Promise<any> {
		const path: string = this.endPointWebservice + "election/DownloadElectionBook";
		const params = {
			election: election,
			idBookType: idBookType,
			reportType: reportType,
			filter: filter
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public DownloadElectionBookMobilityVote(election: any, reportType: number, filter: { IdSection?: number, IdTable?: number } = null): Promise<any> {
		const path: string = this.endPointWebservice + "election/DownloadElectionBookMobilityVote";
		const params = {
			election: election,
			reportType: reportType,
			filter: filter
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetReportAddendum(election: any, idUsers: Array<number>): Promise<any> {
		const path: string = this.endPointWebservice + "election/GetReportAddendum";
		const params = {
			election: election,
			idUsers: idUsers,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public DownloadMilitantListing(idCountry: number = 0, idDistrict: number = 0, idSection: number = 0, idCore: number = 0, structures: Array<any> = [], militantSituations: Array<any> = [], pageBreak: KeyValuePair = null, order: KeyValuePair = null, format: KeyValuePair = null, idReportOption: number = 1, paidQuota: boolean = false, sectionType: number, isMainSection: boolean = true, gender: any, age: number): Promise<any> {
		const path: string = this.endPointWebservice + "reports/DownloadMilitantListing";
		const params = {
			list: {
				IdCountry: idCountry,
				IdDistrict: idDistrict,
				IdSection: idSection,
				IdCore: idCore,
				SectionType: sectionType,
				Structures: structures,
				MilitantSituations: militantSituations,
				PageBreak: pageBreak,
				OrderBy: order,
				Format: format,
				PaidQuota: paidQuota,
				IsMainSection: isMainSection,
				Gender: gender,
				Age: age
			},
			idReportOption: idReportOption
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 * Criar mesa
	 * @param idElection
	 * @param section
	 * @param count
	 */
	public CreateSectionTable(idElection: number, section: any, count: number): Promise<any> {
		const path: string = this.endPointWebservice + "election/CreateSectionTable";
		const params = {
			idElection: idElection,
			section: section,
			count: count
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * remover mesa
	 * @param idElection
	 * @param section
	 * @param idBoard
	 */
	public RemoveSectionTable(idElection: number, section: any, idBoard: number): Promise<any> {
		const path: string = this.endPointWebservice + "election/RemoveSectionTable";
		const params = {
			idElection: idElection,
			section: section,
			idBoard: idBoard
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * remover caderno eleitoral
	 * @param election
	 */
	public ClearElectionBook(election: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/ClearElectionBook";
		const params = {
			election: election
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 * adiciona adendas ao  caderno eleitoral
	 * @param election
	 */
	public AddBookAddenda(election: any, adendas: Array<any>) {
		const path: string = this.endPointWebservice + "election/AddBookAddenda";
		const params = {
			election: election,
			users: adendas
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * lista de militantes exlcuidos de carderno eleitoral
	 * @param election
	 */
	public ElectionBook_Exclusions(election: any): Promise<any> {
		const path: string = this.endPointWebservice + "election/GET_ElectionBook_Exclusions";
		const params = {
			election: election
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Pesquisar militante, retorna dados do militante
	 * @param militantNumber
	 */
	public SearchMilitantToExcludeElectionBook(election: any, militantNumber: number): Promise<any> {
		const path: string = this.endPointWebservice + "election/Book/SearchUser";
		const params = {
			election: election,
			militantNumber: militantNumber
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * exclui militante do caderno eleitoral
	 * @param election
	 */
	public AddBookExclusion(election: any, militant: any, observation: string) {
		const path: string = this.endPointWebservice + "election/AddBookExclusion";
		const params = {
			election: election,
			user: militant,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * remove exlusão de militante do caderno eleitoral
	 * @param election
	 */
	public BookRemoveExclusion(election: any, militant: any) {
		const path: string = this.endPointWebservice + "election/BookRemoveExclusion";
		const params = {
			election: election,
			user: militant
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * @param election
	 */
	public ElectionMobilityVoteList(election: any) {
		const path: string = this.endPointWebservice + "election/GET_Election_MobilityVote";
		const params = {
			election: election
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			console.log(response);

			return response;
		});
	}


	/**
	 * get dados do utilizador com as respectivas permissões
	 */
	public CongressGetInitialData() {
		const path: string = this.endPointWebservice + "congress/Congress_GetInitialData";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idStatistic
	 */
	public CongressGetCongressStatistics(idCongress: number, idStatistic: number = 1) {
		const path: string = this.endPointWebservice + "congress/Congress_GetCongressStatistics";
		const params = {
			idCongress: idCongress,
			idStatistic: idStatistic
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public CongressDownloadStatisticsDocument(idCongress: number) {
		const path: string = this.endPointWebservice + "congress/Congress_DownloadStatisticsDocument";
		const params = {
			idCongress: idCongress
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param militantNumber
	 */
	public CongressSearchCongressUser(idCongress: number, militantNumber: string) {
		const path: string = this.endPointWebservice + "congress/Congress_SearchCongressUser";
		const params = {
			idCongress: idCongress,
			militantNumber: militantNumber
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param militant
	 */
	public CongressRemoveUser(idCongress: number, militant: any) {
		const path: string = this.endPointWebservice + "congress/Congress_RemoveUser";
		const params = {
			idCongress: idCongress,
			user: militant

		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param militant
	 * @param reason
	 * @param observation
	 */
	public CongressRevokeInscriptionPaymentReturn(idCongress: number, militant: any, reason: any, observation: string, iban: string, accountHolderName: string) {
		const path: string = this.endPointWebservice + "congress/Congress_PaymentReturn";
		const params = {
			idCongress: idCongress,
			user: militant,
			reason: reason,
			observation: observation,
			IBAN: iban,
			accountHolderName: accountHolderName
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param searchText
	 * @param pageNumber
	 * @param itemsPerPage
	 */
	public CongressGetCongressReturns(idCongress: number, searchText: string, pageNumber: number = 1, itemsPerPage: number = 100) {
		const path: string = this.endPointWebservice + "congress/Congress_GetCongressReturns";
		const params = {
			idCongress: idCongress,
			searchText: searchText,
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param data
	 */
	public CongressUserReturnIntervention(idCongress: number, data: any) {
		const path: string = this.endPointWebservice + "congress/Congress_UserReturnIntervention";
		const params = {
			idCongress: idCongress,
			data: data,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param data
	 */
	public CongressUserReturnHistoric(data: any) {
		const path: string = this.endPointWebservice + "congress/Congress_UserReturnHistoric";
		const params = {
			data: data,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param militant
	 * @param quality
	 */
	public CongressEditParticipant(idCongress: number, militant: any, quality: Array<any> = []) {
		const path: string = this.endPointWebservice + "congress/Congress_EditParticipant";
		const params = {
			idCongress: idCongress,
			user: militant,
			quality: quality
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param value
	 * @param registType
	 */
	public CongressGetCongressStats(idCongress: number, value: number, registType: any) {
		const path: string = this.endPointWebservice + "congress/Congress_GetCongressStats";
		const params = {
			idCongress: idCongress,
			value: value,
			registType: registType
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 *
	 * @param militantNumber
	 */
	public CongressSearchMilitantbyNumber(idCongress: number, militantNumber: string, value: number, registType: any) {
		const path: string = this.endPointWebservice + "congress/Congress_SearchMilitantbyNumber";
		const params = {
			idCongress: idCongress,
			militantNumber: militantNumber,
			value: value,
			registType: registType
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 */
	public CongressDownloadDocument(idCongress: number, user: any) {
		const path: string = this.endPointWebservice + "congress/Congress_DownloadDocument";
		const params = {
			idCongress: idCongress,
			user: user,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 * @param value
	 * @param registType
	 * @param userType
	 * @param quality
	 */
	public CongressCreateUser(idCongress: number, user: any, value: number, registType: any, userType: any, quality: Array<any> = []) {
		const path: string = this.endPointWebservice + "congress/Congress_CreateUser";
		const params = {
			idCongress: idCongress,
			user: user,
			value: value,
			registType: registType,
			userType: userType,
			quality: quality
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 * @param referenceValidDays
	 */
	public CongressSendSMS(idCongress: number, user: any, referenceValidDays: number = 0) {
		const path: string = this.endPointWebservice + "congress/Congress_SendSMS";
		const params = {
			idCongress: idCongress,
			user: user,
			referenceValidDays: referenceValidDays
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 * @param referenceValidDays
	 */
	public CongressGenerateNewReference(idCongress: number, user: any, referenceValidDays: number = 0) {
		const path: string = this.endPointWebservice + "congress/Congress_GenerateNewReference";
		const params = {
			idCongress: idCongress,
			user: user,
			referenceValidDays: referenceValidDays
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idReportType
	 * @param FilterUserType
	 * @param FilterParticipantQuality
	 * @param FilterRegisterType
	 * @param FilterPaymentState
	 * @param OrderType
	 * @param CongressList
	 * @param code
	 */
	public CongressExportReports(idCongress: number, idReportType: number, FilterUserType: any, FilterParticipantQuality: any, FilterRegisterType: any, FilterPaymentState: any, OrderType: any, CongressList: any = { Id: 0 }, code: string = null) {
		const path: string = this.endPointWebservice + "congress/Congress_ExportReports";
		const params = {
			idCongress: idCongress,
			idReportType: idReportType,
			filters: {
				FilterUserType: FilterUserType,
				FilterParticipantQuality: FilterParticipantQuality,
				FilterRegisterType: FilterRegisterType,
				FilterPaymentState: FilterPaymentState,
				OrderType: OrderType,
				CongressList: CongressList
			},
			code: code
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param name 
	 * @param idReportType 
	 * @returns 
	 */
	public CongressDownloadDelegateContacts(idCongress: number, name: string, idReportType: number) {
		const path: string = this.endPointWebservice + "congress/secure/GET_REPORT_DELEGATE_CONTACTS";
		const params = {
			idCongress: idCongress,
			name: name,
			idReportType: idReportType
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 *
	 */
	public CongressGetListInitialData() {
		const path: string = this.endPointWebservice + "congressList/Congress_GetListInitialData";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idCongress
	 * @param search
	 * @param pageNumber
	 * @param itemsPerPage
	 */
	public CongressGetLists(idCongress: number, search: string, pageNumber: number = 1, itemsPerPage: number = 100) {
		const path: string = this.endPointWebservice + "congressList/Congress_GetLists";
		const params = {
			idCongress: idCongress,
			search: search,
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param list
	 */
	public CongressRemoveList(lists: Array<any>) {
		const path: string = this.endPointWebservice + "congressList/Congress_RemoveList";
		const params = {
			lists: lists
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 * @param organ
	 * @param responsability
	 * @param name
	 * @param idCongress
	 */
	public CongressCreateList(user: any, organ: any, name: string, idCongress: number = 0) {
		const path: string = this.endPointWebservice + "congressList/Congress_CreateList";
		const params = {
			user: user,
			organ: organ,
			name: name,
			idCongress: idCongress
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param newList
	 */
	public CongressChangeList(newList: any) {
		const path: string = this.endPointWebservice + "congressList/Congress_ChangeList";
		const params = {
			newList: newList
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param militantNumber
	 * @param code
	 */
	public CongressListSearchMilitantbyNumber(militantNumber: string, code: string = null) {
		const path: string = this.endPointWebservice + "congressList/CongressList_SearchMilitantbyNumber";
		const params = {
			militantNumber: militantNumber,
			code: code
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 * @param code
	 */
	public CongressGetListDetails(idList: number, code: string = null) {
		const path: string = this.endPointWebservice + "congressList/Congress_GetListDetails";
		const params = {
			idList: idList,
			code: code
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 * @param user
	 * @param role
	 * @param nickname
	 */
	public CongressCreateMember(idList: number, user: any, role: any, nickname: string, code: string = null) {
		const path: string = this.endPointWebservice + "congressList/Congress_CreateMember";
		const params = {
			idList: idList,
			user: user,
			role: role,
			nickname: nickname,
			code: code
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 * @param user
	 * @param code
	 */
	public CongressCreateSubscriber(idList: number, user: any, code: string) {
		const path: string = this.endPointWebservice + "congressList/Congress_CreateSubscriber";
		const params = {
			idList: idList,
			user: user,
			code: code,
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 * @param member
	 * @param code
	 */
	public CongressRemoveSubscriber(idList: number, member: any, code: string) {
		const path: string = this.endPointWebservice + "congressList/Congress_RemoveSubscriber";
		const params = {
			idList: idList,
			sub: member,
			code: code,
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 * @param member
	 */
	public CongressRemoveMember(idList: number, member: any, code: string = null) {
		const path: string = this.endPointWebservice + "congressList/Congress_RemoveMember";
		const params = {
			idList: idList,
			member: member,
			code: code
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 * @param member
	 */
	public CongressEditMember(idList: number, member: any, code: string = null) {
		const path: string = this.endPointWebservice + "congressList/Congress_EditMember";
		const params = {
			idList: idList,
			member: member,
			code: code
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 * @param members
	 */
	public CongressChangeOrder(idList: number, members: any, code: string = null) {
		const path: string = this.endPointWebservice + "congressList/Congress_ChangeOrder";
		const params = {
			idList: idList,
			members: members,
			code: code
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param list
	 */
	public CongressGetListCode(list: any) {
		const path: string = this.endPointWebservice + "congressList/Congress_GetListCode";
		const params = {
			list: list,
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param list
	 * @param phoneNumber
	 */
	public CongressSendCodeBySMS(list: any, phoneNumber: string) {
		const path: string = this.endPointWebservice + "congressList/Congress_SendCodeBySMS";
		const params = {
			list: list,
			phoneNumber: phoneNumber
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param code
	 * @param device
	 */
	public CongressSetExternalSession(code: string, device: any) {
		const path: string = this.endPointWebservice + "congressList/Congress_SetExternalSession";
		const params = {
			code: code,
			device: device
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idList
	 */
	public CongressVerifyListMilitantSituation(idList: number) {
		const path: string = this.endPointWebservice + "congressList/Congress_VerifyListMilitantSituation";
		const params = {
			idList: idList
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	public CreatePoolingStations(idCongress: number, numberOfTables: number) {
		const path: string = this.endPointWebservice + "congress/Congress_CreateCongressTables";
		const params = {
			idCongress: idCongress,
			numberOfTables: numberOfTables
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public RemovePoolingStation(idCongress: number, idTable: number) {
		const path: string = this.endPointWebservice + "congress/Congress_DeleteCongressTable";
		const params = {
			idCongress: idCongress,
			idTable: idTable
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ExecuteVotersDistribution(idCongress: number) {
		const path: string = this.endPointWebservice + "congress/Congress_ExecuteVotersDistribution";
		const params = {
			idCongress: idCongress
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ExportTableVotesList(idCongress: number, idTable: number = -1) {
		const path: string = this.endPointWebservice + "congress/Congress_ExportTableVotesList";
		const params = {
			idCongress: idCongress,
			idTable: idTable
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ExportNationalCommunityVotesList(idCongress: number, idType: number = -1) {
		const path: string = this.endPointWebservice + "congress/Congress_Export_NationalCommunityVotesList";
		const params = {
			idCongress: idCongress,
			idType: idType
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ExportTableVotesListNames(idCongress: number, idTable: number = -1) {
		const path: string = this.endPointWebservice + "congress/Congress_ExportTableVotesNames";
		const params = {
			idCongress: idCongress,
			idTable: idTable
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	//congress extra payments
	/**
	 *
	 * @param candidature
	 */
	public CongressUserCreateExtraPayment(idCongress: number, user: any, idExtraPaymentType: number, sendNotification: boolean, referenceValidDays: number) {
		const path: string = this.endPointWebservice + "congress/Congress_CreateUserExtraPayment";
		const params = {
			idCongress: idCongress,
			user: user,
			idExtraPaymentType: idExtraPaymentType,
			sendNotification: sendNotification,
			referenceValidDays: referenceValidDays,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 * @param referenceValidDays
	 */
	public CongressSendExtraPaymentSMS(idCongress: number, payment: any) {
		const path: string = this.endPointWebservice + "congress/Congress_ResendNotification";
		const params = {
			idCongress: idCongress,
			extraPaymentInfo: payment
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param user
	 */
	public CongressMarkExtraPaymentAsConsumed(idCongress: number, payment: any, observation: any) {
		const path: string = this.endPointWebservice + "congress/Congress_MarkExtraPaymentAsConsumend";
		const params = {
			idCongress: idCongress,
			extraPaymentInfo: payment,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public AvailableCongressList() {
		const path: string = this.endPointWebservice + "congress/GET_AvailableCongressList";
		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idCongress 
	 * @param idReportType 
	 * @returns 
	 */
	public CongressGenerateListComposition(idCongress: number, idReportType: number) {
		const path: string = this.endPointWebservice + "congress/secure/CONGRESS_GET_REPORT_LIST_COMPOSITION";
		const params = {
			idCongress: idCongress,
			idReportType: idReportType
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param name 
	 * @param idReportType 
	 * @returns 
	 */
	public CongressGetPoolingStations(idCongress: number) {
		const path: string = this.endPointWebservice + "congress/Congress_GetCongressTables";
		const params = {
			idCongress: idCongress
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idCongress 
	 * @param militantNumber 
	 * @returns 
	 */
	public CongressSearchCongressUserDelegation(idCongress: number, militantNumber: string) {
		const path: string = this.endPointWebservice + "congress/Congress_SearchCongressUserDelegation";
		const params = {
			idCongress: idCongress,
			militantNumber: militantNumber
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idCongress 
	 * @param original 
	 * @param toDelegate 
	 * @param observation 
	 * @returns 
	 */
	public CongressUserVoteDelegation(idCongress: number, original: any, toDelegate: any, observation: string) {
		const path: string = this.endPointWebservice + "congress/Congress_CongressUserVoteDelegation";
		const params = {
			idCongress: idCongress,
			original: original,
			toDelegate: toDelegate,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 * 
	 * @param idCongress 
	 * @param original 
	 * @param toDelegate 
	 * @param observation 
	 * @returns 
	 */
	public CongressUserVoteDelegationList(idCongress: number, militantNumber: string = "") {
		const path: string = this.endPointWebservice + "congress/Congress_CongressUserVoteDelegationList";
		const params = {
			idCongress: idCongress,
			militantNumber: militantNumber
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idCongress 
	 * @param original 
	 * @param toDelegate 
	 * @param observation 
	 * @returns 
	 */
	public RemoveCongressUserVoteDelegation(delegationVote: any, observation: string) {
		const path: string = this.endPointWebservice + "congress/Congress_CongressRemoveUserVoteDelegation";
		const params = {
			delegationVote: delegationVote,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	//#endregion congress

	/**
	 *
	 * @param candidature
	 */
	public GetCandidatureSignaturesCount(candidature: any) {
		const path: string = this.endPointWebservice + "candidature/GetCandidatureSignaturesCount";
		const params = {
			candidature: candidature,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param searchText
	 */
	public GetCandidatureElectionList(pageNumber: number, itemsPerPage: number, searchText: string) {
		const path: string = this.endPointWebservice + "candidature/GetElectionList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			searchText: searchText
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param candidature
	 * @param signatures
	 */
	public CandidatureAddUserSignature(candidature: any, signatures: Array<any> = [], docValid: boolean = true, isValidSignature: boolean = true) {
		const path: string = this.endPointWebservice + "candidature/AddUserSignature";
		const params = {
			candidature: candidature,
			signatures: signatures,
			docValid: docValid,
			isValidSignature: isValidSignature
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param candidature
	 * @param signature
	 */
	public RemoveSignature(candidature: any, signature: any = null) {
		const path: string = this.endPointWebservice + "candidature/RemoveSignature";
		const params = {
			candidature: candidature,
			signature: signature,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idElection
	 */
	public GetElectionCandidaturesDetails(idElection: number) {
		const path: string = this.endPointWebservice + "candidature/GetElectionCandidaturesDetails";
		const params = {
			idElection: idElection,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param candidature
	 */
	public GetCandidatureSignatures(candidature: any) {
		const path: string = this.endPointWebservice + "candidature/GetCandidatureSignatures";
		const params = {
			candidature: candidature,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public RefreshCandidatureSignatures(candidature: any) {
		const path: string = this.endPointWebservice + "candidature/REFRESH_CandidatureSignatures";
		const params = {
			candidature: candidature,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param number
	 */
	public GetMilitantWarningNotifications(number: string): Promise<any> {
		const path: string = this.endPointWebservice + "warning/GetMilitantWarningNotifications";
		const params = { number: number };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param notification
	 * @param idTarget
	 * @param idNotificationType
	 * @param observation
	 */
	public ResendWarningNotification(notification: any, idTarget: number, idNotificationType: number, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "warning/Militant_ResendWarningNotification";
		const params = {
			notification: notification,
			idTarget: idTarget,
			idNotificationType: idNotificationType,
			observation: observation,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param notification
	 * @param idTarget
	 * @param observation
	 */
	public Militant_GetReport(notification: any, idTarget: number, observation?: string): Promise<any> {
		const path: string = this.endPointWebservice + "warning/Militant_GetReport";
		const params = {
			notification: notification,
			idTarget: idTarget,
			observation: observation,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idDispatch
	 * @param date
	 * @param isActive
	 * @param userId
	 */
	public ChangeWarningDispatch(idDispatch: number, date: string, isActive: boolean, userId: number = null): Promise<any> {
		const path: string = this.endPointWebservice + "warning/ChangeWarningDispatch";
		const params = {
			idDispatch: idDispatch,
			date: date,
			isActive: isActive,
			userid: userId,
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param idDispatch
	 * @param userId
	 */
	public SendWarningDispatch(idDispatch: number): Promise<any> {
		const path: string = this.endPointWebservice + "warning/SendWarningDispatch";
		const params = {
			idDispatch: idDispatch
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 *
	 * @param militantNumber
	 */
	public GetMilitantPayments(expression: string, options: KeyValuePair = null): Promise<any> {
		const path: string = this.endPointWebservice + "payments/GetMilitantPayments";
		const params = {
			expression: expression,
			filter: options
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param militantNumber
	 */
	public MilitantDebtPreview(militantNumber: string): Promise<any> {
		const path: string = this.endPointWebservice + "payments/MilitantDebtPreview";
		const params = {
			militantNumber: militantNumber
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param militantNumber
	 * @param idUserMilitant
	 * @param referenceValidDays
	 * @param idPlatform  // 1 backoffice || 2 app
	 * @param generateReference true [true | false]
	 */
	public MilitantDebtGenerate(militantNumber: string, idUserMilitant: string, referenceValidDays: number, idPlatform: number = 1, generateReference: boolean = true): Promise<any> {
		const path: string = this.endPointWebservice + "payments/MilitantDebtGenerate";
		const params = {
			militantNumber: militantNumber,
			idUserMilitant: idUserMilitant,
			idPlatform: idPlatform,
			referenceValidDays: referenceValidDays,
			generateReference: generateReference
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	public InsertManualPayment(militantNumber: string, documentsToPay: Array<number>, value: number, paymentMethod: number, paymentDate: string, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "payments/CreateManualPayment";
		const params = {
			militantNumber: militantNumber,
			documentsToPay: documentsToPay,
			paidValue: value,
			idPaymentMethod: paymentMethod,
			paymentDate: paymentDate,
			observations: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	InsertManualImmunity(militantNumber: string, immunityType: any, immuntyDate: string, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "payments/CreateManualImmunity";
		const params = {
			militantNumber: militantNumber,
			immunityType: immunityType,
			immunityDate: immuntyDate,
			observations: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	Militant_DeletePayment(payment: any, observation: string): Promise<any> {
		const path: string = this.endPointWebservice + "payments/Militant_DeletePayment";
		const params = {
			payment: payment,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	public Payments_CreateValePostalInvalidPayment(militantNumber, valePostalNumber, value, observations): Promise<any> {
		const path: string = this.endPointWebservice + "payments/CreateValePostalInvalidPayment";
		const params = {
			militantNumber: militantNumber,
			valePostalNumber: valePostalNumber,
			paidValue: value,
			observations: observations
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 *
	 * @param militantNumber
	 * @param idPlatformRequest
	 * @param referenceValidDays
	 * @param idPaymentMethodType
	 * @param requestedValue
	 * @param phoneNumber
	 * @param phoneIndicative
	 * @param persistNewDebt
	 * @param idDocumentReference
	 */
	public GenerateNewReference(militantNumber: string, idPlatformRequest: number, referenceValidDays: number, idPaymentMethodType: number, requestedValue: number, phoneNumber: string, phoneIndicative: string, persistNewDebt: boolean = false, idDocumentReference: number = 0): Promise<any> {
		const path: string = this.endPointWebservice + "payments/GenerateNewReference";
		const params = {
			militantNumber: militantNumber,
			idPlatformRequest: idPlatformRequest,
			referenceValidDays: referenceValidDays,
			idPaymentMethodType: idPaymentMethodType,
			requestedValue: requestedValue,
			phoneNumber: phoneNumber,
			phoneIndicative: phoneIndicative,
			persistNewDebt: persistNewDebt,
			idDocumentReference: idDocumentReference
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Verificar pagamentos em falta
	 */
	public PaymentsGetFailedCallbacksCount(): Promise<any> {
		const path: string = this.endPointWebservice + "pay/Payments_GetFailedCallbacksCount";

		return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * sincronicar pagamentos em falta
	 */
	public PaymentsSyncronizeFailedCallbacks(): Promise<any> {
		const path: string = this.endPointWebservice + "pay/Payments_SyncronizeFailedCallbacks";

		return this.genericRequestPOST(path).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * Retorna os dados referentes ao Menu
	 */
	public GetMenuData(): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "users/GetMenu";

		// token = token ? token : this.AccessToken;

		// this.SetAuthorization(token);

		return this.genericRequestGET(path).then(response => {
			return response;
		});
	}
	// public GetMenuData(): Promise<any> {
	// 	const path: string = './assets/menu-data.json';

	// 	// console.log("...loading json file: " + path);

	// 	return this.http.get<ResultObjectModel>(path, { headers: this.Options })
	// 		.toPromise()
	// 		.then(response => {
	// 			let ro = new ResultObjectModel();
	// 			ro.Object = response;
	// 			ro.Status = 0;

	// 			return ro;
	// 		})
	// 		.catch(this.handleError);
	// };


	//#region DynamicForm
	public Get_ChieldCollectionData(IdCollection: number, IdField: number, Value: number): Promise<any> {
		const path: string = this.endPointWebservice + "form/Get_ChieldCollectionData";
		const params = {
			IdCollection: IdCollection,
			IdField: IdField,
			Value: Value
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	//#endregion


	//#region Payment
	public CreatePrevision(month: number, year: number): Promise<any> {
		const path: string = this.endPointWebservice + "warning/Warnings_GeneratePrevision";
		const params = { month: month, year: year };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	public GetPrevisionList(pageNumber: number, itemsPerPage: number, filter: Filter): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "warning/GetPrevisionList";
		const params = { pageNumber: pageNumber, itemsPerPage: itemsPerPage, filter: filter };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public GetPrevisionDetails(idPrevision: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "warning/GetPrevisionDetails";
		const params = { idPrevision: idPrevision };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetTempWarningData(idPrevision: number, pageNumber: number, itemsPerPage: number, searchText: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "warning/GetTempWarningData";
		const params = { idPrevision: idPrevision, pageNumber: pageNumber, itemsPerPage: itemsPerPage, searchText: searchText };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetWarningProcessNotifications(idPrevision: number, idDispatch: number, idNotificationType: number, searchText: string, pageNumber: number, itemsPerPage: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "warning/GET_WarningProcessNotifications";
		const params = { idPrevision: idPrevision, idDispatch: idDispatch, idNotificationType: idNotificationType, searchText: searchText, pageNumber: pageNumber, itemsPerPage: itemsPerPage };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GET_DatatoExportExcel(idPrevision: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "warning/GET_DatatoExportExcel";
		const params = { idPrevision: idPrevision };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	public GET_WarningProcessLetter(idPrevision: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "warning/GET_WarningProcessLetter";
		const params = { idPrevision: idPrevision };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ChangePrevisionState(idPrevision: number, state: boolean, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/ChangePrevisionState";
		const params = { idPrevision: idPrevision, state: state, observation: observation };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public EditWarningDispatch(idPrevision: number, dispatch: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/EditWarningDispatch";
		const params = { idPrevision: idPrevision, dispatch: dispatch };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	public CreateDistribution(quarter: number, year: number, idDistrict: number, idSection: number): Promise<any> {
		const path: string = this.endPointWebservice + "payments/CREATE_Distribution";
		const params = { quarter: quarter, year: year, idDistrict: idDistrict, idSection: idSection };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetDistributionList(pageNumber: number, itemsPerPage: number, idDistrict: number = -1, idSection: number = -1, searchText: string = ""): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/GET_DistributionList";
		const params = { pageNumber: pageNumber, itemsPerPage: itemsPerPage, idDistrict: idDistrict, idSection: idSection, searchText: searchText };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public DeleteDistribution(idDistribution: number, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/DeleteDistribution";
		const params = { idDistribution: idDistribution, observation: observation };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
			// return this.genericRequestGET(path).then((response: ResultObjectModel) => {
			// 	return response;
		});
	}

	public GetDistributionDetails(idDistribution: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/GET_DistributionDetails";
		const params = { idDistribution: idDistribution };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GetPreviewDistribution(idDistribution: number, pageNumber: number, itemsPerPage: number, searchText: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/GET_DistributionPreview";
		const params = { idDistribution: idDistribution, pageNumber: pageNumber, itemsPerPage: itemsPerPage, searchText: searchText };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public ExecuteDistribution(idDistribution: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/ExecuteDistribution";
		const params = { idDistribution: idDistribution };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public GET_REPORT_Distribution(idDistribution: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/GET_REPORT_Distribution";
		const params = { idDistribution: idDistribution };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public PaymentGenerateReport(startDate: string, endDate: string, paymentTypes: Array<number>): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "payments/RequestPaymentsBetweenDatesReport";
		const params = { startDate: startDate, endDate: endDate, idPaymentMethods: paymentTypes };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public CloseWarningProcess(idPrevision: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "warning/CloseWarningProcess";
		const params = { idPrevision: idPrevision };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 *
	 * @param StatisticType
	 * @param Structure
	 * @param QuotaValid
	 * @param District
	 * @param Section
	 * @param Situation
	 * @param StartDate
	 * @param EndDate
	 * @param ExtensionType
	 * @param idReportType
	 */
	public GetStatisticReport(StatisticType: any, Structure: any, QuotaValid: boolean, Country: any, District: any, Section: any, Situation: any, StartDate: string, EndDate: string, ExtensionType: number, idReportType: number = 0, paidQuota: boolean = false, sectionType: number, isMainSection: boolean = true, gender: any, age: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "statistics/GetStatisticReport";
		const params = {
			filters: {
				StatisticType: StatisticType,
				Structure: Structure,
				QuotaValid: QuotaValid,
				Country: Country,
				District: District,
				Section: Section,
				SectionType: sectionType,
				Situation: Situation,
				StartDate: StartDate,
				EndDate: EndDate,
				ExtensionType: ExtensionType,
				PaidQuota: paidQuota,
				IsMainSection: isMainSection,
				Gender: gender,
				Age: age
			},
			idReportType: idReportType
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	//#endregion

	//#region Candidatures

	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetCandidaturesList(pageNumber: number, itemsPerPage: number, filter: Filter): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_CandidaturesList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			filter: filter
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetSavedCandidaturesList(pageNumber: number, itemsPerPage: number, filter: Filter): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_SavedCandidaturesList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			filter: filter
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetSavedCandidaturesConcluded(pageNumber: number, itemsPerPage: number, filter: Filter): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_ClosedCandidaturesList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			filter: filter
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param pageNumber
	 * @param itemsPerPage
	 * @param filter
	 */
	public GetSavedCandidaturesArchived(pageNumber: number, itemsPerPage: number, filter: Filter): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_CandidaturesArchiveList";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			filter: filter
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 *
	 * @param id
	 */
	public GetCandidatureDetails(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_CandidatureDetails";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 */
	public GetCandidatureArchivedDetails(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_MasterCandidatureDetails";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 */
	public GetCandidatureFullDetails(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_CandidatureFullDetails";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param data
	 */
	public CandidatureCreateNewMilitantReferences(data: any[]): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/CreateNewMilitantReferences";
		const params = { data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param data
	 */
	public GenerateReportCandidatureCSV(data: any[]): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GenerateReportCandidatureCSV";
		const params = { data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public CandidatureArchiveProcess(data: any[]): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/ArchiveProcess";
		const params = { data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param intervention
	 */
	public InterventionAddDocuments(idCandidature: number, intervention: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/InterventionAddDocuments";
		const params = {
			idCandidature: idCandidature,
			intervention: intervention
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param data
	 */
	public GenerateLettersCandidature(data: any[]): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GenerateWelcomeLettersCandidature";
		const params = { data: data };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param id
	 */
	public GetAvaliableCandidatureStages(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GetAvaliableCandidatureStages";
		const params = { id: id };

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param id
	 * @param idNewProcessStage
	 */
	public ForceAutomaticCandidatureStage(id: number, idNewProcessStage: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/ForceAutomaticCandidatureStage";
		const params = {
			id: id,
			idNewProcessStage: idNewProcessStage
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 * @param filename
	 */
	public GetCandidatureDocument(id: number, filename: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_CandidatureDocument";
		const params = {
			id: id,
			filename: filename
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 */
	public ValidateCandidatureExistence(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/ValidateCandidatureExistence";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 */
	public GetCandidatureInterventions(id: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_CandidatureInterventions";
		const params = { id: id };
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 * @param message
	 * @param observation
	 * @param document
	 */
	public ApproveCandidatureStage(id: number, message: string, observation: string, document: string = null): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/ApproveCandidatureStage";
		const params = {
			id: id,
			message: message,
			observation: observation,
			document: document
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 * @param message
	 * @param observation
	 * @param reasons
	 * @param document
	 */
	public DeclineCandidatureStage(id: number, message: string, observation: string, extraObservation: string, reasons: IKeyValuePair[], document: string = null): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/DeclineCandidatureStage";
		const params = {
			id: id,
			message: message,
			observation: observation,
			extraObservation: extraObservation,
			reasons: reasons,
			document: document
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
 *
 * @param id
 * @param message
 * @param observation
 * @param document
 */
	public ApproveCandidatureInterventionStage(id: number, message: string, observation: string, document: string = null): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/ApproveSectionCandidature";
		const params = {
			id: id,
			message: message,
			observation: observation,
			document: document
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param id
	 * @param message
	 * @param observation
	 * @param reasons
	 * @param document
	 */
	public DeclineCandidatureInterventionStage(id: number, message: string, observation: string, reasons: IKeyValuePair[], document: string = null): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/DeclineSectionCandidature";
		const params = {
			id: id,
			message: message,
			observation: observation,
			reasons: reasons,
			document: document
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 *
	 * @param data
	 * @param intervention
	 */
	public CandidatureIntervention(idCandidature: number, intervention: ICandidatureIntervention): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/CandidatureIntervention";
		const params = {
			idCandidature: idCandidature,
			intervention: intervention
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	// REVER -> ADICIONAR DOCUMENTO para "desistencia" processo de candidatura do candidato, ação deve estar disponivel na area de detalhes da candidatura apos iniciar o processo
	/**
	 * 
	 * @param data 
	 * @param intervention 
	 * @returns 
	 */
	public CandidatureWithdrawal(data: ICandidature, intervention: ICandidatureIntervention): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/CandidatureWithdrawal";
		const params = {
			data: data,
			intervention: intervention
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idCandidature 
	 * @param observation 
	 * @param receptionDate 
	 * @returns 
	 */
	public RecoverCandidature(idCandidature: number, observation: string, receptionDate: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/RecoverCandidatureProcess";
		const params = {
			id: idCandidature,
			observation: observation,
			dateReception: receptionDate
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param file 
	 * @returns 
	 */
	public ChangeCandidatureDocument(file: any): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/Change_CandidatureDocument";
		const params = {
			document: file
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	public EditCandidatePersonalData(data: ICandidature): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/Edit_CandidatePersonalData";
		const params = {
			data: data
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param id 
	 * @param admissionDate 
	 * @param proponent 
	 * @param sectionAproved 
	 * @returns 
	 */
	public CandidatureCreateMilitant(id: number, admissionDate: string, proponent: any = null, sectionAproved: boolean = false): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/CreateMilitant";
		const params = {
			id: id,
			admissionDate: admissionDate,
			proponent: proponent,
			sectionAproved: sectionAproved
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}


	public CandidatureValidateFieldExistence(field: string, fieldType: number, guid: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/Candidate_ValidateFieldDuplicatedData";
		const params = {
			field: field,
			fieldType: fieldType,
			guid: guid
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param searchNumber 
	 * @returns 
	 */
	public CandidatureSearchProponent(searchNumber: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/Candidature_SearchProponent";
		const params = {
			searchNumber: searchNumber
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idCandidatureProcess 
	 * @param idCandidatureStage 
	 * @returns 
	 */
	public GetCandidatureHistoricIntervention(idCandidatureProcess: number, idCandidatureStage: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_CandidatureHistoricIntervention";
		const params = {
			idCandidatureProcess: idCandidatureProcess,
			idCandidatureStage: idCandidatureStage
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idCandidatureProcess 
	 * @param idCandidatureStage 
	 * @returns 
	 */
	public GetCandidatureArchivedHistoricIntervention(idCandidatureProcess: number, idCandidatureStage: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "candidatures/GET_MasterCandidatureHistoricIntervention";
		const params = {
			idCandidatureProcess: idCandidatureProcess,
			idCandidatureStage: idCandidatureStage
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	public UploadTempDocument(file: any): Promise<ResultObjectModel> {
		const path = this.endPointWebservice + "candidatures/upload_TempDocument";
		const params = {
			document: file
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});

	}

	public UploadDocumentArchiveFile(idUser: number, document: any, name: string, observation: string): Promise<ResultObjectModel> {
		const path = this.endPointWebservice + "militant/Militant_AddDocumentToDocumentArchive";
		const params = {
			idUser: idUser,
			document: document,
			name: name,
			observation: observation
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});

	}

	//#endregion

	//#region militants contact update processes

	/**
	 * 
	 * @param pageNumber 
	 * @param itemsPerPage 
	 * @param idState 
	 * @param search 
	 * @returns 
	 */
	public GetContactUpdateProcessList(pageNumber: number, itemsPerPage: number, idState: number = -1, search: string = ''): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "militant/MILITANT_GET_ContactUpdateProcessLIST";
		const params = {
			pageNumber: pageNumber,
			itemsPerPage: itemsPerPage,
			idState: idState,
			search: search
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	/**
	 * 
	 * @param idProcess 
	 * @returns 
	 */
	public GetContactUpdateProcessDetails(idProcess: number): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "militant/MILITANT_GET_ContactUpdateProcess_Details";
		const params = {
			idProcess: idProcess
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idProcess 
	 * @param observation 
	 * @returns 
	 */
	public MilitantContactProcessApprove(idProcess: number, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "militant/Militant_ContactProcess_Aprove";
		const params = {
			idProcess: idProcess,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idProcess 
	 * @param observation 
	 * @returns 
	 */
	public MilitantContactProcessReject(idProcess: number, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "militant/Militant_ContactProcess_Reject";
		const params = {
			idProcess: idProcess,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idProcess 
	 * @param observation 
	 * @returns 
	 */
	public MilitantContactProcessApproveSectionVote(idProcess: number, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "militant/Militant_UpdateSection_Aprove";
		const params = {
			idProcess: idProcess,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param idProcess 
	 * @param observation 
	 * @returns 
	 */
	public MilitantContactProcessRejectSectionVote(idProcess: number, observation: string): Promise<ResultObjectModel> {
		const path: string = this.endPointWebservice + "militant/Militant_UpdateSection_Reject";
		const params = {
			idProcess: idProcess,
			observation: observation
		};
		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}
	//#endregion


	/**
	 * 
	 * @param lang 
	 */
	public GetTranslations(lang: string = "pt"): Promise<string> {
		const path: string = this.endPointWebservice + "lang/translations";
		const params = { locale: lang };
		return this.basicRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response.Object;
		});
	}


	/**
	 * Retorna resources que estejam no servidor
	 * @param language
	 */
	public GetMoreResources(language: string): Promise<any> {
		const path = "assets/i18n/" + language + ".json?nocache='" + new Date().getTime() + "'";

		return this.http.get<any>(path, { headers: this.Options })
			.toPromise()
			.then(response => {
				return response;
			})
			.catch(this.handleError);
	}


	/**
	 * Pedido POST generico
	 * @param _path
	 * @param _params
	 */
	private genericRequestPOST(path: string, params?: any): Promise<ResultObjectModel> {
		const expire = this.sessionService.Get() && this.sessionService.Get().ExpiresDateObject ? new Date(this.sessionService.Get().ExpiresDateObject.getTime() - new Date().getTime()).getMinutes() : 60;

		console.log("session time -> " + expire);

		return new Promise((resolve, reject) => {
			if (this.endPointWebservice === undefined) {
				resolve(new ResultObjectModel());
			}
			else if (expire && expire < this.timeToRefresh
				&& (path != this.endPointWebservice + 'auth/authorizationcode'
					&& path != this.endPointWebservice + 'auth/token')) { // minutes

				this.http.get<ResultObjectModel>(this.endPointWebservice + 'auth/updatetoken', { headers: this.Options })
					.toPromise()
					.then(response => {
						// verificar
						// this.AuthorizationCode = null;

						if (response.Status === WebService.RESULT_OPERATION.SUCCESS) {

							this.SetAuthorization(response.Object.AccessToken);
							let session = this.sessionService.Get();
							session.AccessToken = response.Object.AccessToken;
							session.ExpiresDate = response.Object.ExpiresDate;
							session.Expires = response.Object.Expires;
							session.DateCreate = response.Object.DateCreate;
							this.sessionService.Set(session);

						}

						return;

					}).then(() => {
						this.http.post<ResultObjectModel>(path, params, { headers: this.Options })
							.toPromise()
							.then(response => {
								switch (response.Status) {
									case WebService.RESULT_OPERATION.INVALID_TOKEN:
									case WebService.RESULT_OPERATION.UNAUTHORIZED:
										this.NavController.navigateForward([PAGES.LOGIN.path], { animated: true });
										break;
									default: break;
								}

								resolve(response);
								return;

							}).catch(error => {
								resolve(new ResultObjectModel());

								this.handleError(error);
							});

					}).catch(error => {
						this.SetAuthorization(null);
						this.sessionService.Set(null);
						resolve(new ResultObjectModel());
						this.NavController.navigateForward([PAGES.LOGIN.path], { animated: true });
						this.handleError(error);
					});
			}
			else {
				this.http.post<ResultObjectModel>(path, params, { headers: this.Options })
					.toPromise()
					.then(response => {
						switch (response.Status) {
							case WebService.RESULT_OPERATION.INVALID_TOKEN:
							case WebService.RESULT_OPERATION.UNAUTHORIZED:
								this.NavController.navigateForward([PAGES.LOGIN.path], { animated: true });
								break;
							default: break;
						}

						resolve(response);

					}).catch(error => {
						resolve(new ResultObjectModel());

						this.handleError(error);
					});
			}
		});
	}

	/**
	 * Pedido GET generico
	 * @param path
	 */
	private genericRequestGET(path: string): Promise<ResultObjectModel> {

		const expire = this.sessionService.Get() && this.sessionService.Get().ExpiresDateObject ? new Date(this.sessionService.Get().ExpiresDateObject.getTime() - new Date().getTime()).getMinutes() : 60;

		console.log("session time -> " + expire);

		return new Promise((resolve, reject) => {
			if (this.endPointWebservice === undefined) {
				resolve(new ResultObjectModel());
			}
			else if (expire && expire < this.timeToRefresh
				&& (path != this.endPointWebservice + 'auth/authorizationcode'
					&& path != this.endPointWebservice + 'auth/updatetoken')) { // minutes

				this.http.get<ResultObjectModel>(this.endPointWebservice + 'auth/updatetoken', { headers: this.Options })
					.toPromise()
					.then(response => {
						// verificar
						// this.AuthorizationCode = null;

						if (response.Status === WebService.RESULT_OPERATION.SUCCESS) {

							this.SetAuthorization(response.Object.AccessToken);
							let session = this.sessionService.Get();
							session.AccessToken = response.Object.AccessToken;
							session.ExpiresDate = response.Object.ExpiresDate;
							session.Expires = response.Object.Expires;
							session.DateCreate = response.Object.DateCreate;
							this.sessionService.Set(session);
						}

						return;

					}).then(() => {
						this.http.get<ResultObjectModel>(path, { headers: this.Options })
							.toPromise()
							.then(response => {
								switch (response.Status) {
									case WebService.RESULT_OPERATION.INVALID_TOKEN:
									case WebService.RESULT_OPERATION.UNAUTHORIZED:
										this.NavController.navigateForward([PAGES.LOGIN.path], { animated: true });
										break;
									default: break;
								}

								resolve(response);
							})
							.catch(error => {
								resolve(new ResultObjectModel());

								this.handleError(error);
							});

					}).catch(error => {
						this.SetAuthorization(null);
						this.sessionService.Set(null);
						resolve(new ResultObjectModel());
						this.NavController.navigateForward([PAGES.LOGIN.path], { animated: true });
						this.handleError(error);
					});
			}
			else {
				this.http.get<ResultObjectModel>(path, { headers: this.Options })
					.toPromise()
					.then(response => {
						switch (response.Status) {
							case WebService.RESULT_OPERATION.INVALID_TOKEN:
							case WebService.RESULT_OPERATION.UNAUTHORIZED:
								this.NavController.navigateForward([PAGES.LOGIN.path], { animated: true });
								break;
							default: break;
						}

						resolve(response);
					})
					.catch(error => {
						resolve(new ResultObjectModel());

						this.handleError(error);
					});
			}
		});
	}

	/**
	 * 
	 * @param regions 
	 * @param isMilitant 
	 * @param typeInscription 
	 * @param order 
	 * @param thematic 
	 * @param idReportOption 
	 * @returns ResultObjectModel()
	 */
	public DownloadMembersCENReport(regions, isMilitant: number,
		typeInscription: number, order: KeyValuePair = null, thematic: number, idReportOption: number): Promise<any> {
		const path: string = this.endPointWebservice + "CEN/GetMembersList";

		const params = {
			filters: {
				Regions: regions,
				IsMilitant: isMilitant,
				TipoInscricao: typeInscription,
				OrderBy: order,
				Thematics: thematic,
				IdReportOption: idReportOption
			}
		};

		return this.genericRequestPOST(path, params).then((response: ResultObjectModel) => {
			return response;
		});
	}

	/**
	 * 
	 * @param path 
	 */
	private externalRequestGET(path: string): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.endPointWebservice === undefined) {
				resolve(new ResultObjectModel());
			}
			else {
				this.http.get<any>(path, {})
					.toPromise()
					.then(response => {
						resolve(response.results);
					})
					.catch(error => {
						resolve(new ResultObjectModel());

						this.handleError(error);
					});
			}
		});
	}

	/**
	 * 
	 * @param path 
	 */
	private basicRequestGET(path: string): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.endPointWebservice === undefined) {
				resolve({});
			}
			else {
				this.http.get<any>(path, {})
					.toPromise()
					.then(response => {
						resolve(response);
					})
					.catch(error => {
						resolve({});

						this.handleError(error);
					});
			}
		});
	}

	private basicRequestPOST(path: string, params: any): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.endPointWebservice === undefined) {
				resolve(new ResultObjectModel());
			}
			else {
				this.http.post<any>(path, params, {})
					.toPromise()
					.then(response => {
						resolve(new ResultObjectModel(response));
					})
					.catch(error => {
						resolve(new ResultObjectModel());

						this.handleError(error);
					});
			}
		});
	}

	/**
	 * 
	 * @param error 
	 */
	private handleError(error: any) {
		console.log("error: ", error);
	}


}

