import { InputMaskDirective } from './input-pattern';
import { TooltipDirective } from './tooltip.directive';
import { NgModule } from "@angular/core";
import { StatusColorDirective } from "./status-color.directive";
import { ButtonActionsDirective } from './button-actions.directive';
import { KeyboardShortcutsDirective } from "./keyboard-shortcuts.directive";
import { CustomScrollbarDirective } from './custom-scrollbar.directive';
import { CongressActionsDirective } from './congress-actions.directive';
import { LowercaseInputDirective } from './lowercase-input.directive';

@NgModule({
    imports: [],
    declarations: [
        StatusColorDirective,
        ButtonActionsDirective,
        KeyboardShortcutsDirective,
        CustomScrollbarDirective,
        TooltipDirective,
        InputMaskDirective,
        CongressActionsDirective,
        LowercaseInputDirective
    ],
    exports: [
        StatusColorDirective,
        ButtonActionsDirective,
        KeyboardShortcutsDirective,
        CustomScrollbarDirective,
        TooltipDirective,
        InputMaskDirective,
        CongressActionsDirective,
        LowercaseInputDirective
    ]
})
export class DirectivesModule { }
